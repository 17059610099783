import React from "react";
// import Carousel from "react-slick";
import Img from "gatsby-image";
// import VerticalCard from "ps-components/Card/VerticalCard.jsx";
import classNames from "classnames";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import productListStyle from "ps-assets/jss/productListStyle.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { Link } from "gatsby";
import Layout from "ps-components/Layout/Layout.jsx";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import Hidden from "@material-ui/core/Hidden";
import { browserWindow, browserDocument } from "util/localStorageHelper";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import MediaQuery from "react-responsive";
import PortabilityForm from "templates/PortabilityForm";
import { wizardLMSCall } from "util/lmsRequest";
import IndividualPageSEO from "./seo/IndividualPageSEO";
import breadcrumbsjson from 'jsons/breadcrumbs';

class IndividualPage extends React.Component {

    state ={
      recommendedPlans:[],
      hovered: false,
      mode: `buy`,
      form: {
        name: ``,
        mobile: ``,
        renew: ``,
        policy: ``,
        insuranceType: ``
      },
      links: {
        myself: `/comprehensive-health-insurance-plan`,
        family: `/women-care-insurance`,
        parents: `/health-insurance-plan-for-senior-citizens`
      },
      adults: {
        myself: `1`,
        family: `1`,
        parents: `2`
      },
      children: {
        myself: `0`,
        family: `1`,
        parents: `0`
      },
      webPSupport: true,
      submitStatus: false,
      signupModal: false,
    };
  
    componentDidMount=()=>{
      this.addLdScript(breadcrumbsjson[browserWindow.location.pathname])
    }

    updateSignUpModal = (x) => {
      this.setState(x);
    }
  
    handleClickOpen(modal) {
      var x = [];
      x[modal] = true;
      this.updateSignUpModal(x);
    }
  
    handleClose(modal) {
      var x = [];
      x[modal] = false;
      this.updateSignUpModal(x);
    }
  
    setMode(mode) {
      this.setState({
        mode: mode
      });
    }
  
    setInputValueToState = (event, value) => {
      if(event.target.id == `name`){
        if(event.target.value[0] != ' '){
          this.setState({
            ...this.state,
            form: {
              ...this.state.form,
              [value]: event.target.value
            }
          });
        }
      }
      else if (event.target.id === `mobile`) {
        if (!isNaN(event.target.value) && event.target.value.length <= 10) {
          this.setState({
            ...this.state,
            form: {
              ...this.state.form,
              [value]: event.target.value
            }
          });
        }
      }
        else this.setState({
          ...this.state,
          form: {
            ...this.state.form,
            [value]: event.target.value
          }
        });
      // this.setState({
      //   [event.target.value]: event.target.value
      // });
      event.preventDefault();
    };
  
    buy = () => {
    };
    handleSimple = event => {
      this.setState({
        form: { ...this.state.form, insuranceType: event.target.value }
      });
    };
  
    handleMouseOver = id => {
      this.setState({
        [id]: true
      });
    };
    handleBuyNow = (event) => {
  
      let { name, mobile} = this.state.form;
      let urlInfo = browserWindow.location.href
  
      const error = () => {
        if(!this.state.form.mobile || (this.state.form.mobile.length != 10 && this.state.form.mobile.length != 0)){
          return true
        }
        else return false
      }
      
      let errorCheck = error()
      if(errorCheck){
        event.preventDefault()
      }
      else wizardLMSCall(
        name,
        mobile,
        null,
        null,
        null,
        urlInfo,
      );
  
      this.setState({
        submitStatus: true
      });
    };
    
    handleMouseLeave = id => {
      this.setState({
        [id]: false
      });
    };

    addLdScript=(script)=>{
      let ldjsonScript = browserDocument.createElement(`script`)
      ldjsonScript.type=`application/ld+json`
      ldjsonScript.id=`ld-json-script/bradcrumbs`
      if(!browserDocument.getElementById('ld-json-script') && script){
        ldjsonScript.innerHTML = `${JSON.stringify(script)}`;
        browserDocument.body.appendChild(ldjsonScript);
      }
    }

    render(){

      const { classes, data, pageContext } = this.props;
        const categoryData = data.strapiInsuranceplancategories;
        let url = process.env.BASE_URL;
        let urlold = process.env.BASE_URL_OLD;
        
        const bannerContent = (item, matches, classes) => (
            <div
            className={`${classes.container}`}
            style={{
              alignSelf: `center`,
              marginTop: 0 ,
            }}
          >
            <>
                <GridContainer
                  style={{
                    justifyContent: `left`,
                    marginTop: matches ? `0px` : `25px`
                  }}
                >
                  <h1 className={classNames(
                      classes.headerStyle
                    )}>{categoryData.name}</h1>
                  <br/>
                  <p className={classNames(
                      classes.parastyleparentspage
                    )} style={{marginLeft: `-36.5%`}}
                  >{categoryData.summary}</p>
                  <br/>
                  <br/>
                  <GridItem
                    xs={12}
                    sm={3}
                    md={3}
                    lg={2}
                    className={classNames(
                      // classes.mlAuto,
                      // classes.mrAuto,
                      classes.textCenter,
                      classes.buttonPadding,
                      classes.buynowparentspage
                    )}
                  >
                    <Button
                      block
                      color={
                        this.state.mode === `buy` ||
                        // (item.sequence !== 2 && 
                          this.state.mode === ``
                          // )
                          ? `primary`
                          : `transparent`
                      }
                      className={`${classes.button} ${
                        this.state.mode !== `buy` ||
                        // (item.sequence === 2 && 
                          this.state.mode === ``
                          // )
                          ? matches
                            ? classes.primaryBorder
                            : classes.allBorder
                          : ``
                      }`}
                      onClick={() => this.setMode(`buy`)}
                    >
                      I want to Buy
                    </Button>
                  </GridItem>
                  <GridItem
                    xs={12}
                    sm={3}
                    md={3}
                    lg={2}
                    className={classNames(
                      // classes.mlAuto,
                      // classes.mrAuto,
                      classes.textCenter,
                      classes.buttonPadding,
                      classes.portstylepage
                    )}
                  >
                    <Button
                      value="i want to port"
                      aria-label="i want to port"
                      block
                      color={
                        this.state.mode === `port` ||
                        // (item.sequence === 2 && 
                          this.state.mode === ``
                          // )
                          ? `primary`
                          : `transparent`
                      }
                      className={`${classes.button} ${classes.buttonMargin} ${
                        this.state.mode === `port` ||
                        // (item.sequence === 2 && 
                          this.state.mode === ``
                          // )
                          ? ``
                          : matches
                            ? classes.primaryBorder
                            : classes.allBorder
                      }`}
                      onClick={() => window.location.href = '/portability-plans'}
                    >
                      I want to Port
                    </Button>
                  </GridItem>
                </GridContainer>
                <GridContainer
                  style={{
                    justifyContent: `flex-start`,
                  }}
                >
                  <GridItem
                    xs={12}
                    sm={12}
                    md={8}
                    lg={6}
                    className={classNames(
                      // classes.mlAuto,
                      // classes.mrAuto,
                      classes.textCenter,
                      classes.cardmargin
                    )}
                  >
                    {this.state.mode !== `port` &&
                      <Card
                        raised
                        className={`${classes.card} ${classes.cardStyle}`}
                        style={{
                          marginTop: `50px`
                        }}
                      >
                        {(this.state.mode === `buy` ||
                          // (item.sequence !== 2 && 
                            this.state.mode === ``
                            // )
                            ) 
                            && (
                          <CardBody
                            formHorizontal
                            style={{
                              paddingTop: `0px`,
                              paddingBottom: `0px`
                            }}
                          >
                            <form aria-label="" onSubmit={this.buy}>
                              <GridContainer
                                style={{
                                  alignItems: `center`,
                                }}
                              >
                                <GridItem xs={12} sm={3} md={3}>
                                  <CustomInput
                                    labelText="Your Name"
                                    id="name"
                                    role="presentation"
                                    formControlProps={{
                                      fullWidth: true,
                                      className: classes.formControl
                                    }}
                                    inputProps={{
                                      "aria-label": `customName`,
                                      name: `customName`
                                    }}
                                    value={this.state.form.name}
                                    onChange={e => this.setInputValueToState(e, `name`)}
                                  />
                                </GridItem>
                                <GridItem xs={12} sm={3} md={3}>
                                  <CustomInput
                                    labelText="Your Number"
                                    id="mobile"
                                    role="presentation"
                                    formControlProps={{
                                      fullWidth: true,
                                      className: classes.formControl
                                    }}
                                    inputProps={{
                                      required: true,
                                      pattern: `[0-9]{10}`,
                                      maxLength: `10`,
                                      inputMode: `tel`,
                                    }}
                                    error={
                                      (this.state.submitStatus && !this.state.form.mobile ) ||
                                      (this.state.form.mobile.length != 10 && this.state.form.mobile.length != 0)
                                    }
                                    value={this.state.form.mobile}
                                    onChange={e => this.setInputValueToState(e, `mobile`)}
                                  />
                                </GridItem>
                                <GridItem xs={12} sm={3} md={3}>
                                  <FormControl
                                    fullWidth
                                    className={`${classes.selectFormControl} ${classes.formMargin}`}
                                    error={
                                      this.state.submitStatus &&
                                      this.state.form.insuranceType === ``
                                    }
                                  >
                                    <InputLabel
                                      htmlFor="insurancetype"
                                      className={classes.selectLabel}
                                      style={{width: 110}}
                                    >
                                      I want to Insure
                                    </InputLabel>
                                    <Select
                                      MenuProps={{
                                        className: classes.selectMenu
                                      }}
                                      classes={{
                                        select: classes.select
                                      }}
                                      value={this.state.form.insuranceType || ``}
                                      role="none"
                                      onChange={this.handleSimple}
                                      style={{textAlign: `left`}}
                                      inputProps={{
                                        name: `insurancetype`,
                                        id: `insurancetype`
                                      }}
                                      className={classNames(
                                        classes.insurestyle
                                      )}
                                    >
                                      <MenuItem
                                        classes={{
                                          root: classes.selectMenuItem,
                                          selected: classes.selectMenuItemSelected
                                        }}
                                        value="myself"
                                      >
                                        Myself
                                      </MenuItem>
                                      <MenuItem
                                        classes={{
                                          root: classes.selectMenuItem,
                                          selected: classes.selectMenuItemSelected
                                        }}
                                        value="family"
                                      >
                                        My Family
                                      </MenuItem>
                                      {/* <MenuItem
                                        classes={{
                                          root: classes.selectMenuItem,
                                          selected: classes.selectMenuItemSelected
                                        }}
                                        value="parents"
                                      >
                                        My Parents & in laws
                                      </MenuItem> */}
                                    </Select>
                                  </FormControl>
                                </GridItem>
                                <GridItem
                                  xs={12}
                                  sm={3}
                                  md={3}
                                  style={{
                                    alignSelf: `center`
                                  }}
                                >
                                  <Link
                                    to={
                                      this.state.links[this.state.form.insuranceType]
                                        ? this.state.links[
                                          this.state.form.insuranceType
                                        ] +
                                          `?name=${this.state.form.name}&mobile=${this.state.form.mobile}&noOfAdults=${
                                            this.state.adults[
                                              this.state.form.insuranceType
                                            ]
                                          }&noOfChildren=${
                                            this.state.children[
                                              this.state.form.insuranceType
                                            ]
                                          }`
                                        : `/`
                                    }
                                  >
                                    <Button
                                      block
                                      color="primary"
                                      value="buy now"
                                      aria-label="buy now"
                                      onClick={this.handleBuyNow}
                                      className={`${classes.button} formFillBuyNowGA`}
                                    >
                                     <span className={`formFillBuyNowGA`}> Buy Now</span>
                                    </Button>
                                  </Link>
                                </GridItem>
                              </GridContainer>
                            </form>
                          </CardBody>)}
                      </Card>
                    }
                  </GridItem>
                </GridContainer>
                
              </>
            </div>
          )

      
          let bgImage =
                    browserWindow.navigator.userAgent.indexOf(`Edge`) !== -1
                      ? categoryData.bannerImage4 && `url(${categoryData.bannerImage3.publicURL})`
                      : this.state[`banner`]
                        ? categoryData.bannerImage3 && `url(${categoryData.bannerImage3.publicURL})`
                        : categoryData.bannerImage4 && `url(${categoryData.bannerImage4.publicURL})`;
                    
        return(
          <Layout
            // image2={allPlansArr && allPlansArr.length > 0 && data.allStrapiInsuranceplans.edges[0].node.category == `special` &&
            //   categoryData &&
            // categoryData.bannerImage2 &&
            // categoryData.bannerImage2.childImageSharp.fluid
            // }
            small={true}
            title={categoryData.name}
            summary={categoryData.summary}
          >
            <Helmet key="helmetTags">
              <title>{categoryData.titleTag}</title>
            </Helmet>
            {process.env.ENABLE_HELMET == `true` && (
              <Helmet key="helmetTags">
                <link rel="canonical" href={urlold + `health-insurance/individuals/`} />
                {categoryData.metaTitle != null && (
                  <meta name={`title`} content={`${categoryData.metaTitle}`} />
                )}
                {categoryData.metaDescription != null && (
                  <meta
                    name={`description`}
                    content={`${categoryData.metaDescription}`}
                  />
                )}
                {categoryData.metaKeywords != null && (
                  <meta name={`keywords`} content={`${categoryData.metaKeywords}`} />
                )}
                <meta property="og:title" content={categoryData.metaTitle} />
                <meta property="og:description" content={categoryData.metaDescription} />
                <meta property="og:url" content={url + `${pageContext.metaPath}`}/>
                <meta name={categoryData.metaTitle} />
                <meta property="twitter:title" content={categoryData.metaTitle} />
                <meta name="twitter:description" content={categoryData.metaDescription} />
                <meta property="twitter:url" content={url + `${pageContext.metaPath}`} />
              </Helmet>
            )}
            <MediaQuery maxWidth={599}>
                  {matches => {
                    return (
                      <>
                        {matches && (
                          <span>
                            <Img style={{ marginTop: `5%` }} fluid={categoryData.bannerImage2.childImageSharp.fluid} />
                          </span>
                        )}
                        <Hidden implementation="css" xsDown>
                        <div
                          className={classes.pageHeader}
                          style={{
                            backgroundImage: bgImage,
                            backgroundBlendMode: `saturation`,
                            marginTop: `5%`,
                          }}
                          onMouseEnter={
                            matches
                              ? null
                              : () => this.handleMouseOver(`banner`)
                          }
                          onMouseLeave={
                            matches
                              ? null
                              : () => this.handleMouseLeave(`banner`)
                          }
                        >
                          <div>
                            {bannerContent(null, matches, classes)}
                          </div>
                        </div>
                        </Hidden>
                        <Hidden implementation="css" smUp>
                        <div
                          className={classes.pageHeader}
                          style={{
                            backgroundBlendMode: `saturation`,
                            backgroundColor: `white`,
                            marginTop: `2%`
                          }}
                          onMouseEnter={
                            matches
                              ? null
                              : () => this.handleMouseOver(`banner-` + 0)
                          }
                          onMouseLeave={
                            matches
                              ? null
                              : () => this.handleMouseLeave(`banner-` + 0)
                          }
                        >
                          <div>
                            {bannerContent(null, matches, classes)}
                          </div>
                        </div>
                        </Hidden>
                      </>
                    );
                  }}
                </MediaQuery>
                <IndividualPageSEO />
                <PortabilityForm handleClose={(modal) => this.handleClose(modal)} signupModal={this.state.signupModal} />
        </Layout>
        )
    }
}

IndividualPage.propTypes = {
    classes: PropTypes.object,
    data: PropTypes.object,
    location: PropTypes.object,
    pageContext: PropTypes.object
  };

export default withStyles(productListStyle)(IndividualPage);

export const individualPageQuery = graphql`
  query IndividualPage($category: String!) {
    allStrapiInsuranceplans(
      filter: { category: { eq: $category }, isVisible: { eq: true } }
    ) {
      edges {
        node {
          id
          productId
          name
          urlPath
          summary
          category
          isRecommended
          recommendationLabel
          isWithdrawn
          redirectUrl
          isVisiblelist
          thumbnailImages {
            publicURL
          }
        }
      }
    }
    allStrapiInsuranceplancategories(filter: { category: { ne: $category } }) {
      edges {
        node {
          name
          summary
          category
          url
          colorCode
          ctaLabel
          image {
            publicURL
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
    strapiInsuranceplancategories(category: { eq: "individual" }) {
      name
      summary
      category
      url
      colorCode
      titleTag
      metaTitle
      metaKeywords
      metaDescription
      bannerImage2 {
        publicURL
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      bannerImage3 {
        publicURL
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      bannerImage4 {
        publicURL
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`;