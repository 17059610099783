import React from "react";
import classNames from "classnames";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import WellnessBenefitsStyle from "ps-assets/jss/WellnessBenefitsStyle.jsx";
import {  browserWindow } from "util/localStorageHelper";
import { Helmet } from "react-helmet";
import Button from 'components/CustomButtons/Button.jsx';
// import Paper from "@material-ui/core/Paper";
// import Table from "@material-ui/core/Table";
// import TableBody from "@material-ui/core/TableBody";
// import TableCell from "@material-ui/core/TableCell";
// import TableHead from "@material-ui/core/TableHead";
// import TableRow from "@material-ui/core/TableRow";
import Accordion from "components/Accordion/Accordion.jsx";

const faq = [
    {
        title:'What is the difference between Individual and Family Floater health insurance plans?',
        description:<h5>In individual health insurance plans, each individual that holds a policy is entitled up to a chosen sum insured. However, in a family floater health insurance policy, the total sum insured is shared by all the family members in any proportion.</h5>,
        content:false
    },
    {
        title:'Do I need Individual health insurance policy if I have an employee health insurance already?',
        description:<h5>Yes. The cover may not be sufficient for an employee, as it might provide only a basic or a limited coverage. Currently, the average coverage requirement ranges above 10 lakhs. In such a case, the individual can buy a top-up plan along with basic employee health insurance cover.</h5>,
        content:false
    },
    {
        title:'Can I add my family members in Individual health insurance policies?',
        description:<h5>Yes, you can add your family members in your individual health insurance cover. You can use the mid-term inclusion option, in which you can add a newly wedded spouse / new born baby / legally adopted child.</h5>,
        content:false
    },
    {
        title:'Is there an age limit to buy Individual health insurance plan?',
        description:<h5>As per guidelines issued by IRDAI, 65 years would be the maximum entry age for an individual health insurance. Insurers has a flexible option of plans to cover, regardless of age. Once you’re covered, renewal option is for a lifetime.</h5>,
        content:false
    },
    {
        title:'Will there be any additional costs other than regular premiums to be paid?',
        description:<h5>No, we do not encourage any additional premium to be paid apart from the premium quoted by the insurance company. If any person or institution asking an additional cost other than the premium, is considered unethical. Premium is the cost of insurance which has to be paid by the proposer to the insurer for the health insurance cover.</h5>,
        content:false
    },
]

const IndividualPageSEO = ({ classes }) => {
    let AccordionArray = faq.map((category) => {
      return {
        title: category.title,
        content: (
          <>
            {category.content ? (
              category.description
            ) : (
              <h5>{category.description}</h5>
            )}
          </>
        ),
      };
    });

  return (
    <GridContainer className={classes.containerDisplay}>
      <GridItem
        md={11}
        sm={11}
        xs={11}
        className={classNames(
          classes.mrAuto,
          classes.mlAuto,
          classes.infoArea,
          classes.handleMarginRight
        )}
      >
        {/* <GridItem style={{ paddingLeft: "0px" }}>
          <h2 className={classes.h2hTitle}>Health Insurance for Individuals</h2>
        </GridItem> */}
        <GridItem style={{ paddingLeft: "0px" }}>
          <h2 className={classes.h2hTitle}>
            The Importance of a Health Insurance
          </h2>
        </GridItem>
        <h5>
          The current COVID-19 pandemic has made the entire world sit up and
          realise that medical emergencies are unpredictable and can cause a
          financial upheaval that is tough to handle. People have started to
          understand the importance of a health insurance plan with the arising
          medical costs. Apart from this, access to good medical facility and
          hospitalisation can be a financial burden for many. Therefore, one
          must know the benefits of a health insurance policy that can provide
          the added long-term protection in times like these. Even though while
          having the financial confidence to take care of your loved ones, a
          health insurance plan is extremely useful when it comes to beating
          medical inflation.
        </h5>
        <h5>
          Here are 5 recommended reasons why you need to get a Health Insurance
          plan today:
        </h5>
        <h3 className={classes.h3textStyle}>1. To fight lifestyle diseases</h3>
        <h5>
          Lifestyle diseases like diabetes, obesity, respiratory problems, heart
          disease are on rise - especially among the people under the age of 45.
          Lack of healthy lifestyle, stress, pollution, leading an undisciplined
          life may cause such complications. Even though you follow
          precautionary measures, an unfortunate incident to face a sudden high
          hospital expenditure will be a tough phase to get through. Therefore
          investing in a Mediclaim Policy that covers regular medical tests can
          be a kick-start to cope up with medical expenses, leaving you
          stress-free.
        </h5>
        <h3 className={classes.h3textStyle}>
          2. To safeguard your family members
        </h3>
        <h5>
          While you opt for a highly beneficial insurance policy, you should
          always choose to secure the whole family with insurance plan that
          would suit your requirements (i.e) if you have a suitable medical
          insurance, you need not stress about getting the best and the advanced
          treatment. In such a case, a basic plan along with a top-up plan would
          be ideal. Also, a health insurance company will always provide
          professional counselling for you to choose the plan in which you would
          have the maximum benefit.
        </h5>
        <h3 className={classes.h3textStyle}>
          3. To deal with pre and post-hospitalisation conditions
        </h3>
        <h5>
          Apart from the cost of treatment, your medical bill would also include
          the expense of pre and post hospitalisation conditions. The costs for
          doctor’s consultation, diagnosis tests, ambulance charges, operation
          theatre costs, medicines, room rent, etc. are simultaneously
          increasing. All of these could put an additional strain on your
          finances if you fail to have a suitable health insurance cover. By
          paying an affordable health insurance premium each year, you can beat
          the burden of medical inflation while opting for quality treatment.
        </h5>
        <h3 className={classes.h3textStyle}>4. To protect your savings</h3>
        <h5>
          While an unexpected illness can lead to mental stress, there is
          another side you need to think about - the expenses. By purchasing a
          health insurance policy, you are transferring your risk to the
          insurance company while the insurer manages a pool of people to help
          them with their medical expenses. Additionally, a health insurance
          lets you avail tax benefits, which further increases your savings. In
          short, you can manage your medical expenses without dripping your
          savings off.
        </h5>
        <h3 className={classes.h3textStyle}>5. Insure early to stay secured</h3>
        <h5>
          Having a medical insurance early in life has numerous benefits. When
          you buy a health cover early in life, you can avail plans at lower
          rates and you can enjoy the continuity benefits as well. Additionally,
          you will be offered extensive coverage options by the health insurance
          company.
        </h5>
        <GridItem style={{ paddingLeft: "0px" }}>
          <h2 className={classes.h2hTitle}>
            How to select the right Health Insurance policy?
          </h2>
        </GridItem>
        <h5>
          Here are some tips that would help you to choose the right insurance
          cover for you and your family:
        </h5>
        <ul>
          <li>
            Look for a contingency plan as a solution for any exigencies, with
            add-on benefits / talk to the insurance consultant to get a clear
            view on the policy.
          </li>
          <li>Keep it affordable</li>
          <li>Always opt for a higher sum insured</li>
          <li>Check the range of network hospitals near you</li>
        </ul>
        <GridItem style={{ paddingLeft: "0px" }}>
          <h2 className={classes.h2hTitle}>
            Health Insurance policy for an Individual
          </h2>
        </GridItem>
        <h5>
          Individual health insurance policies offers coverage to a single
          person on an individual sum insured basis. This particular sum
          insured, can solely be utilised by the insured for his medical
          expenses.
        </h5>
        <GridItem style={{ paddingLeft: "0px" }}>
          <h2 className={classes.h2hTitle}>
            Recommended plans on Individual basis
          </h2>
        </GridItem>
        <ul>
          <li>
            <h3>
              <b>Star Comprehensive Insurance Policy</b>
            </h3>
            <h5>
              Under this health insurance plan any person between the ages 18
              and 65 at the time of entry can be covered with the benefit of
              lifetime renewal. This health insurance plan provides coverage for
              individuals based on the various sum insured options. Individuals
              can choose the specified sum insured option with a minimum of 5
              lakhs and a maximum of 1 crore based on his/her needs.
            </h5>
            <h5>
              Having a comprehensive cover keeps your finances intact and
              ensures you get covered for all your medical expenses like
              in-patient hospitalisation, emergency transport charges, daycare
              treatments, annual health check-ups and much more.
            </h5>
            <h4>
              <b>
                What is covered under the Star Comprehensive Insurance Policy?
              </b>
            </h4>
            <h5>
              The coverage offered under the Star Comprehensive Insurance Policy
              are as follows:
            </h5>
            <ul>
              <li>In-patient hospitalisation expenses</li>
              <li>Pre and post-hospitalisation expenses</li>
              <li>Daycare procedures / treatments</li>
              <li>Domiciliary hospitalisation</li>
              <li>Emergency Road and Air ambulance expenses</li>
              <li>AYUSH treatments</li>
              <li>Second medical opinion</li>
              <li>Delivery and new-born cover</li>
              <li>Organ donor expenses</li>
              <li>Bariatric surgery</li>
              <li>
                Personal accident cover – lump sum benefit for death & permanent
                total disablement
              </li>
              <li>Hospital cash benefit</li>
              <li>Modern treatments</li>
              <li>Annual health check ups</li>
              <li>Wellness services</li>
            </ul>
            <h4>
              <b>What is not covered?</b>
            </h4>
            <h5>
              The following list comprises of a partial list of policy
              exclusions. For a detailed list, you can refer to the policy
              document.
            </h5>
            <ul>
              <li>
                Pre-existing medical conditions until the completion of the
                waiting period
              </li>
              <li>
                Any hospitalisation with the primary goal of obtaining
                diagnostic information
              </li>
              <li>Treatment outside of India</li>
              <li>
                Circumcision, sex-change surgery, cosmetic surgery, and plastic
                surgery
              </li>
              <li>
                Refractive error repair less than 7.5 diopters , hearing
                impairment correction, corrective and aesthetic dental surgery
              </li>
              <li>Hazardous or adventure sports-related injuries</li>
              <li>Unproven treatments</li>
              <li>Venereal diseases and STDs (other than HIV)</li>
              <li>Nuclear weapon and war-related perils</li>
              <li>Intentional self-injury</li>
            </ul>
            <h4>
              <b>Waiting periods</b>
            </h4>
            <ul>
              <li>
                A 30-day waiting period is applicable for all treatments except
                in case of accidents.
              </li>
              <li>
                The policy will cover pre-existing diseases after a waiting
                period of 36 months from the inception date of the policy.
              </li>
              <li>
                Specific diseases mentioned in the policy document will be
                covered after a waiting period of 24 months from
                the inception date of the policy.
              </li>
            </ul>
            <h4>
              <b>Buyback PED (optional cover)</b>
            </h4>
            <h5>
              Under the Star Comprehensive Insurance Policy, an optional feature
              is introduced named as the ‘The Buyback PED’. While you choose
              this cover, the waiting period for pre-existing diseases will be
              reduced from 36 to 12 months on payment of additional premium.
            </h5>
            <h4>
              <b>Tax Benefits</b>
            </h4>
            <h5>
              One can avail tax benefit on the premium amount under
              Section 80D of the Income Tax Act 1961-21.
            </h5>
            <h4>
              <b>Eligibility Criteria</b>
            </h4>
            <h5>
              Individuals between 18 years and 65 years of age at the time of
              entry can take this Insurance. Dependent children can be covered
              from 3 months and up to 25 years of age.
            </h5>
            <Button
              variant="contained"
              color="primary"
              className={`${classes.button}`}
              onClick={() => {
                browserWindow.location.href =
                  "/comprehensive-health-insurance-plan";
              }}
            >
              Know more
            </Button>
          </li>
          <li>
            <h3>
              <b>Medi Classic Insurance Policy (Individual)</b>
            </h3>
            <h5>
              This health insurance plan is an affordable health insurance plan
              that provides a variety of benefits that will help individuals
              manage their medical expenses. This uniquely designed health plan
              covers individual from 16 days to 65 years of age, with a lifelong
              renewal option. The plan is also available under Gold variant.
            </h5>
            <h5>
              Medi classic Insurance Policy (Individual) Gold plan offers sum
              insured options ranging between Rs. 3 lakhs - Rs. 25 lakhs. It
              exclusively covers organ donor expenses up to sum insured, AYUSH
              treatments, domiciliary hospitalisation, modern treatments and
              much more.
            </h5>
            <h4>
              <b>
                What is covered under the Medi classic Insurance Policy
                (Individual)?
              </b>
            </h4>
            <h5>
              The coverage offered under the Medi classic Insurance Policy
              (Individual) Gold Plan are as follows:
            </h5>
            <ul>
              <li>In-patient hospitalisation</li>
              <li>Pre and post-hospitalisation</li>
              <li>Emergency ambulance</li>
              <li>Non-allopathic treatments</li>
              <li>New born baby cover (from 16th day after birth)</li>
              <li>Domiciliary hospitalisation</li>
              <li>Modern treatments</li>
              <li>Psychiatric and Psychosomatic disorders </li>
              <li>Annual health check-ups</li>
              <li>Daycare treatments</li>
              <li>Shared accommodation</li>
              <li>Road Traffic Accident (RTA)</li>
              <li>Super Restoration of basic sum insured</li>
              <li>Automatic Restoration</li>
              <li>Organ donor expenses</li>
            </ul>
            <h4>
              <b>What is not covered?</b>
            </h4>
            <h5>
              The following list comprises of a partial list of policy
              exclusions. For a detailed list, you can refer to the policy
              document.
            </h5>
            <ul>
              <li>Rest cure, rehabilitation and respite care</li>
              <li>Change of gender treatments</li>
              <li>
                Cosmetic / plastic surgery unless for a reconstruction followed
                by an accident
              </li>
              <li>
                Injuries caused due to hazardous / adventurous sports and
                activities
              </li>
              <li>Any unproven treatment</li>
              <li>Refractive error less than 7.5 dioptre</li>
              <li>
                Expenses incurred upon maternity, sterility and infertility
              </li>
            </ul>
            <h4>
              <b>Waiting periods</b>
            </h4>
            <ul>
              <li>
                A 30-day waiting period is applicable for all treatments except
                in case of accidents.
              </li>
              <li>
                The policy will cover pre-existing diseases after a waiting
                period of 48 months from the policy’s date of inception.
              </li>
              <li>
                Specific diseases mentioned in the policy document will be
                covered after a waiting period of 24 months from
                the inception date of the policy.
              </li>
            </ul>
            <h4>
              <b>Tax Benefits</b>
            </h4>
            <h5>
              One can avail tax benefit on the premium amount under
              Section 80D of the Income Tax Act 1961-21.
            </h5>
            <h4>
              <b>Eligibility Criteria</b>
            </h4>
            <h5>
              Any individual between the age of 16 days to 65 years can take
              this insurance.
            </h5>
            <h5>
              Beyond 65 years, the policy provides a lifelong renewable option.
            </h5>
            <Button
              variant="contained"
              color="primary"
              className={`${classes.button}`}
              onClick={() => {
                browserWindow.location.href = "/mediclassic-insurance-policy";
              }}
            >
              Know more
            </Button>
          </li>
          <li>
            <h3>
              <b>Young Star Insurance Policy</b>
            </h3>
            <h5>
              Earlier, there was an assumption that health insurances are only
              for old people.
            </h5>
            <h5>
              With the arising health complications, even the younger
              generations today have started to realise the need for a health
              insurance. This situation has also made us realise that unforeseen
              situations might occur at any point of life. By opting policy at
              young age, you can get higher coverage at lower premium
            </h5>
            <h5>
              Young Star Insurance Policy is specially crafted with a motive to
              ensure young adults have a serene lifestyle and get instant access
              to the best health care facilities without any hassle. This plan
              has been specially tailor-made for young individuals under the age
              of 40 who want to lead a healthy lifestyle for themselves and
              their families. You can get this health insurance under individual
              plan basis.
            </h5>
            <h5>
              The Young Star Insurance Policy provides wide sum insured options
              of Rs. 3 lakhs to Rs. 1 crore. Through this, you can enjoy wide
              range of benefits such as incentive-led wellness programs,
              discount on renewals, the lowest waiting periods, coverage for pre
              and post-hospitalisation expenses, cumulative bonus, hospital cash
              benefits, annual check-ups, automatic restoration of the sum
              insured and additional basic sum insured for road accidents.
            </h5>
            <h4>
              <b>What is covered under the Young Star Insurance Policy?</b>
            </h4>
            <h5>
              The coverage offered under the Young Star Insurance Policy are as
              follows:
            </h5>
            <ul>
              <li>In-patient hospitalisation</li>
              <li>Pre and Post-hospitalisation</li>
              <li>Emergency road ambulance</li>
              <li>Day care treatments</li>
              <li>Delivery expenses (Gold)</li>
              <li>Hospital cash benefit (Gold)</li>
              <li>Modern treatments</li>
              <li>Road Traffic Accident (RTA)</li>
              <li>Automatic Restoration of sum insured</li>
              <li>Mid-term inclusion of spouse or new born baby</li>
              <li>Annual health check-ups</li>
              <li>E-medical opinion</li>
              <li>Wellness programs</li>
            </ul>
            <h4>
              <b>What is not covered?</b>
            </h4>
            <h5>
              The following list comprises of a partial list of policy
              exclusions. For a detailed list, you can refer to the policy
              document.
            </h5>
            <ul>
              <li>Expenses related to gender change and obesity.</li>
              <li>
                Expenses related to cosmetic or plastic surgery unless for a
                reconstruction following an accident
              </li>
              <li>Expenses for treatment of infertility or sterility</li>
              <li>
                Expenses for treatment due to war, nuclear attack or invasion
              </li>
              <li>
                Correction of eye sight due to refractive error less than 7. 5
                dioptres{" "}
              </li>
              <li>Intentional self injury</li>
              <li>
                Injury/disease directly or indirectly caused by or arising from
                or attributable to war, invasion, act of foreign enemy, warlike
                operations
              </li>
            </ul>
            <h4>
              <b>Waiting Periods</b>
            </h4>
            <ul>
              <li>
                A 30-day waiting period is applicable for all treatments except
                in case of accidents.
              </li>
              <li>
                The policy will cover pre-existing diseases after a waiting
                period of 12 months from the policy’s date of inception.
              </li>
              <li>
                Specific diseases mentioned in the policy document will be
                covered after a waiting period of 12 months from
                the inception date of the policy.
              </li>
            </ul>
            <h4>
              <b>Tax Benefits</b>
            </h4>
            <h5>
              One can avail tax benefit on the premium amount under
              Section 80D of the Income Tax Act 1961-21.
            </h5>
            <h4>
              <b>Eligibility</b>
            </h4>
            <h5>
              People between 18 years and 40 years of age at the time of entry
              can take this insurance. Dependent children can be covered from 91
              days and up to 25 years of age.
            </h5>
            <Button
              variant="contained"
              color="primary"
              className={`${classes.button}`}
              onClick={() => {
                browserWindow.location.href = "/young-star-insurance";
              }}
            >
              Know more
            </Button>
          </li>
          <li>
            <h3>
              <b>Star Micro Rural and Farmers Care</b>
            </h3>
            <h5>
              India is one of the largest populated countries. Health insurance
              is optional, but health care is free for all citizens at
              Individual State & Government Facilities, which have challenges
              with staff & supplies. The percentage of the rural population in
              India was at 65.07 % in 2020. - reported by World Bank
            </h5>
            <h5>
              <b>Star Micro Rural and Farmers Care: </b>
              is specially designed only for rural farmers to be self-reliant
              towards healthcare. This policy is offered to rural residents to
              individual or farmer’s families in the age group of 1 year to 65
              years. An individual can opt for Rs. 1 Lakh and family with Rs. 2
              lakhs sum insured options. The policy has an easy instalment
              premium payment option of quarterly & half-yearly. The Medical
              Expenses for Road Ambulance, daycare treatments, and modern
              treatments like Chemotherapy, Dialysis, and Robotic surgeries are
              payable. The waiting period for Pre-existing Diseases and
              specified illnesses is just Six months from the policy inception
              date.
            </h5>
            <Button
              variant="contained"
              color="primary"
              className={`${classes.button}`}
              onClick={() => {
                browserWindow.location.href = "/micro-rural-and-farmers-care";
              }}
            >
              Know more
            </Button>
          </li>
          <li>
            <h3>
              <b>Senior Citizens Red Carpet Health Insurance Policy</b>
            </h3>
            <h5>
              Health insurance is most needed for elderly People as exposed to
              age-related illnesses. A Prolonged or Costly treatment would
              impact their savings, but buying health insurance after 60 + would
              be a costly proposition. Star health understands, welcomes the
              elderly with respect, and caters to the needs of their complete
              medical or treatment expenses with a red carpet.
            </h5>
            <h5>
              <b>Senior Citizens Red Carpet Health Insurance Policy </b>
              is designed only for individuals/ families in the age group of 60
              years to 75 years with lifelong renewal options. The Pre-insurance
              medical screening is not mandatory. The Pre-existing disease (PED)
              waiting period is reduced to 12 months from the date of policy
              inception, declaring that PEDs are always advantageous. The Policy
              has various Sum insured options to choose from 1 / 2 /3 /4/ 5/ 7.5
              /10/ 15 /20 /25 lakhs. Health insurance premiums won’t increase as
              age increases like other insurance policies in the marketplace.
            </h5>
            <Button
              variant="contained"
              color="primary"
              className={`${classes.button}`}
              onClick={() => {
                browserWindow.location.href =
                  "/health-insurance-plan-for-senior-citizens";
              }}
            >
              Know more
            </Button>
          </li>
          <li>
            <h3>
              <b>Star Women Care Insurance Policy</b>
            </h3>
            <h5>
              Health insurance plan specially caters to the needs of women
              factors influencing the needs of women are compiled together and
              designed for women and their families. The best health insurance
              plan is well crafted for women towards hospitalization in
              different stages of life.
            </h5>
            <h5>
              <b>Star Women Care Insurance Policy</b> is a policy aimed towards
              the welfare of ladies as individuals or families with spouses and
              children ages between 91 days to 75 Years with various sum
              insured options of Rs. 5 /10 /15 /20 /25 /50 /100 Lakhs.
              Women-centric treatment expenses are payable like Maternity,
              Antenatal (Pregnancy Care), In-utero Surgeries, In-utero-fetal
              repairs, newborns, Pre & Postnatal along with sterilisation
              procedures.
            </h5>
            <Button
              variant="contained"
              color="primary"
              className={`${classes.button}`}
              onClick={() => {
                browserWindow.location.href = "/women-care-insurance";
              }}
            >
              Know more
            </Button>
          </li>
          <li>
            <h3>
              <b>Star Health Premier Insurance Policy</b>
            </h3>
            <h5>
              Best Health insurance bring security and protection to all. In
              India, people aged 50 + years are prone to higher health risks
              related to younger ones. Get a health insurance plan designed for
              individuals or families above 50 years with a wide range of
              coverage. Financial assistance during hospitalisation brings a lot
              of contentment and courage to face the situation.
            </h5>
            <h5>
              <b>Star Health Premier Insurance Policy </b>
              is an All-inclusive Health insurance Policy offered by the Health
              Insurance Specialist to accommodate Families or individuals above
              the age of 50 years with no upper limit.
            </h5>
            <h5>
              This policy offers various sum-insured options from 10 lakhs to 1
              crore. This health insurance plan pays for AYUSH, Bariatric
              surgeries, modern treatments, Air Ambulance, Homecare treatments,
              Outpatient consultations, and many more.
            </h5>
            <Button
              variant="contained"
              color="primary"
              className={`${classes.button}`}
              onClick={() => {
                browserWindow.location.href = "/health-premier-insurance";
              }}
            >
              Know more
            </Button>
          </li>
          <li>
            <h3>
              <b>Super Surplus Insurance Policy</b>
            </h3>
            <h5>
              Many have suffered monetary loss & lost their loved or dear ones
              in the financial battle of medical treatment, leading to
              bankruptcy. Some believe that being covered under the corporate
              policy would be enough for their medical treatment costs. Having a
              cost-effective top-Up health insurance plan with sufficient cover
              is always recommended.
            </h5>
            <h5>
              <b>Super Surplus Insurance Policy - (Top-up Gold Plan)</b> is an
              additional cover along with the existing health insurance policy
              to cater to the ever-changing treatment costs during
              hospitalization in the most economical way.
            </h5>
            <h5>
              <b>Super Surplus Insurance Policy</b> offers indemnity cover from
              5 lakhs to 1 crore Sum-insured above certain defined limits. The
              policyholder is to pay a certain amount in the Policy period for
              admissible claims under the health insurance policy. This Policy
              offers coverage for Air / Road Ambulance, Day-Care Procedures, Pre
              & Post hospitalization, Maternity, and Modern treatments
            </h5>
            <Button
              variant="contained"
              color="primary"
              className={`${classes.button}`}
              onClick={() => {
                browserWindow.location.href =
                  "/super-surplus-health-insurance-policy";
              }}
            >
              Know more
            </Button>
          </li>
        </ul>
        <GridItem style={{ paddingLeft: "0px" }}>
          <h2 className={classes.h2hTitle}>Frequently Asked Questions</h2>
        </GridItem>
        <div className={classes.section}>
          <div className={classes.container}>
            <GridContainer
              className={`${classes.container} ${classes.textCenter}`}
            >
              <GridItem
                md={12}
                style={{ paddingLeft: "0px" }}
                className={`${classes.mrAuto} ${classes.mlAuto} ${classes.textCenter}`}
              ></GridItem>
              <GridItem
                style={{ paddingLeft: "0px" }}
                className={`${classes.mrAuto} ${classes.textCenter}`}
                xs={12}
                sm={12}
                md={12}
              >
                <Accordion active={0} collapses={AccordionArray} />
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </GridItem>
      {process.env.ENABLE_HELMET == `true` && (
        <Helmet>
          <script type="application/ld+json">
            {`
            {
              "@context":"https://schema.org",
              "@type":"FAQPage",
              "mainEntity":[
                 {
                    "@type":"Question",
                    "name":"What is the difference between Individual and Family Floater health insurance plans? ",
                    "acceptedAnswer":{
                       "@type":"Answer",
                       "text":" In individual health insurance plans, each individual that holds a policy is entitled up to a chosen sum insured. However, in a family floater health insurance policy, the total sum insured is shared by all the family members in any proportion. "
                    }
                 },
                 {
                    "@type":"Question",
                    "name":"Do I need Individual health insurance policy if I have an employee health insurance already?",
                    "acceptedAnswer":{
                       "@type":"Answer",
                       "text":"Yes. The cover may not be sufficient for an employee, as it might provide only a basic or a limited coverage. Currently, the average coverage requirement ranges above 10 lakhs. In such a case, the individual can buy a top-up plan along with basic employee health insurance cover.  "
                    }
                 },
                 {
                    "@type":"Question",
                    "name":"Can I add my family members in Individual health insurance policies? ",
                    "acceptedAnswer":{
                       "@type":"Answer",
                       "text":"Yes, you can add your family members in your individual health insurance cover. You can use the mid-term inclusion option, in which you can add a newly wedded spouse / new born baby / legally adopted child.  "
                    }
                 },
                 {
                    "@type":"Question",
                    "name":"Is there an age limit to buy Individual health insurance plan? ",
                    "acceptedAnswer":{
                       "@type":"Answer",
                       "text":"As per guidelines issued by IRDAI, 65 years would be the maximum entry age for an individual health insurance. Insurers has a flexible option of plans to cover, regardless of age. Once you’re covered, renewal option is for a lifetime.  "
                    }
                 },
                 {
                    "@type":"Question",
                    "name":"Will there be any additional costs other than regular premiums to be paid? ",
                    "acceptedAnswer":{
                       "@type":"Answer",
                       "text":"No, we do not encourage any additional premium to be paid apart from the premium quoted by the insurance company. If any person or institution asking an additional cost other than the premium, is considered unethical. Premium is the cost of insurance which has to be paid by the proposer to the insurer for the health insurance cover. "
                    }
                 }
              ]
           }
            `}
          </script>
        </Helmet>
      )}
    </GridContainer>
  );
};

IndividualPageSEO.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object,
  pageContext: PropTypes.object,
};
export default withStyles(WellnessBenefitsStyle)(IndividualPageSEO);